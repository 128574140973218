import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { FiChevronRight } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import "../header/menu/Menus.css"
import "./SubCategories.css"
function SubCategories() {
  const [data, setdata] = useState(null)

  const getData = async () => {
    const res = await axios.get(`https://onlineparttimejobs.in/api/category/filter`, { withCredentials: true })
    setdata(res.data)
  }
  useEffect(() => {
    getData()
  }, [])
  return (
    <section className='' style={{ position: "sticky", top: 0 ,zIndex:'1',margin:"10px 0"}}>
      <div className=' container-fluid bg_sub p-0'>
        <div className='d-flex align-items-center'>
          <div className='container'>
            <ul className='d-flex p-0 m-0'>
              {data && data?.map((item, i) => {
                return <li className="nav-item perent" key={i}>
                  <Link to={`product/category/${item.title._id}`} className="nav-link">
                    {item.title.name}
                    <i className="bx bx-chevron-down"></i>
                  </Link>

                  {item.Submenu.length > 0 && <ul className={`dropdown-menu dropAgro ${i}`}>
                    <div className="row">
                      <div className="col-lg-3 width-100pr">

                        <div className="menuList" style={{ paddingTop: "0px" }}>
                          <ul>
                            {item.Submenu && item.Submenu.map((item) => {
                              return <li className="nav-item" key={item._id}>
                                <Link to={`product/category/${item._id}`} className="nav-link sub_menu_Text">
                                  <FiChevronRight /> {item.name}
                                </Link>
                              </li>
                            })}

                          </ul>
                        </div>
                      </div>

                    </div>

                  </ul>}
                </li>
              })}
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SubCategories

import { useParams } from "react-router-dom";
import CartOrecel from "./CartOrecel";
import CartdOrecalsPro from "./CartdOrecalsPro";
import ContenstOrecal from "./ContenstOrecal";
import RelatedOrecels from "./RelatedOrecels";
import axios from "axios";
import { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
function OrecelProducts({ dataproduct, details, data }) {
  const params = useParams();
  const [data2, setData2] = useState(null);
  const getAllDeta2 = async () => {
    try {
      const user = await axios.get(
        `https://onlineparttimejobs.in/api/categoryAttribute/public/category/${params.id}`,
        {
          withCredentials: true,
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          },
        }
      );
      // debugger
      setData2(user.data);
      console.log("dataaa", user.data);
    } catch (error) {}
  };
  useEffect(() => {
    getAllDeta2();
  }, [params?.id]);
  return (
    <div>
      <div className="container">
        <div className="row">
          <ContenstOrecal details={details} />
        </div>
        {/* <div className="row">
                {data && data?.map((item, i) => {
                    return <CartOrecel show={i % 2 == 0} item={item} />
                })}

            </div> */}

        {/* <div className="vehicleTab">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    {data2 && data2?.map((item, i) => {
                        return <li key={i} className="nav-item" role="presentation">
                            <button
                                className={`nav-link ${i == 0 && 'active'}`}
                                id="engine-tab"
                                data-bs-toggle="tab"
                                data-bs-target={`#engine-tab-pane${i}`}
                                type="button"
                                role="tab"
                                aria-controls={`engine-tab-pane${i}`}
                                aria-selected="true"
                            >
                              
                                <span>{item?.navigation}</span>
                            </button>
                        </li>
                    })}


                </ul>
                <div className="tab-content" id="myTabContent">
                    {data2 && data2?.map((item, i) => {
                        return <div
                            className={`tab-pane fade show ${i == 0 && 'active'}`}
                            id={`engine-tab-pane${i}`}
                            role="tabpanel"
                            aria-labelledby="engine-tab"
                            tabIndex={0}
                        >
                            <div className="innerTabCard">
                                <div className="row">
                                 
                                    <div className="col-lg-9">
                                        <ul className="listInfo">
                                          ssad
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    })}


                </div>
            </div> */}

        <Tabs
          defaultActiveKey="Rigorous tests"
          id="justify-tab-example"
          className="mb-3"
          justify
        >
          {data2 &&
            data2?.map((item) => {
              return (
                <Tab eventKey={item?.navigation} title={item?.navigation}>
                  <div className="tabMain d-flex">
                    {!!item?.banner_img?.url && (
                      <div style={{ height: "300px", width: "100%" }}>
                        <img
                          style={{ width: "100%", height: "100%" }}
                          src={item?.banner_img?.url}
                        />
                      </div>
                    )}
                    <div
                      style={{
                        margin: "0 20px",
                        flex: "1",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div className="details-data">
                        <h2>{item?.name}</h2>
                        <p>{item?.description}</p>
                      </div>
                    </div>
                  </div>
                </Tab>
              );
            })}
        </Tabs>

        <div className="row">
          <RelatedOrecels />
        </div>

        <div className="row">
          {dataproduct &&
            dataproduct?.map((item, i) => {
              return <CartdOrecalsPro key={i} item={item} />;
            })}
        </div>
      </div>
    </div>
  );
}
export default OrecelProducts;

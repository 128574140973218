import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { base_url } from "../../server";
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
// import { auth } from "../../FireBase";
import { firebase, auth } from '../../FireBase';



function OtpLogin(props) {


    const [show, setShow] = useState(true)
    const [logIn, setLogIn] = useState({
        entity: ''
    })
    const [otp, setSetOtp] = useState({
        token: '',
        otp: ''
    })
    // const navigate = useNavigate()
    const [extToken, SetExtToken] = useState()

    const navigate = useNavigate()
    const handleShow = () => {
        if (show) {
            setShow(!show)
        } else {
            setShow(!show)
        }


    }


    const handleLogin = (e) => {
        const clone = { ...logIn }
        const val = e.target.value
        const name = e.target.name
        clone[name] = val
        setLogIn(clone)
    }

    const handleOtp = (e) => {
        const clone = { ...otp }
        const val = e.target.value
        const name = e.target.name
        clone[name] = val
        setSetOtp(clone)
    }

    const handleClickOtp = async () => {
        try {
            const res = await axios.post(`${base_url}auth/email/register`, logIn)
            handleShow()
            window.localStorage.setItem("otptoken", res?.data.token);
        } catch (error) {

        }
        // try {
        //     if (verifiedPhone(logIn.entity)) {
        //         const res = await axios.post(`${base_url}auth/email/register`, logIn)
        //         handleShow()
        //         window.localStorage.setItem("otptoken", res?.data.token);
        //     } else {
        //         alert('Email Id must be business only')
        //     }
        // } catch (error) {

        // }
    }

    const otpVerify = async () => {
        try {
            const res = await axios.post(`${base_url}auth/email/verify`, { token: window.localStorage.getItem("otptoken"), otp: otp.otp })
            props.onHide()
            window.localStorage.setItem("isLogin", true);
            window.localStorage.setItem("token", res?.data.token);
            SetExtToken(res?.data)
            navigate("/");

        } catch (error) {
            alert(error)
        }
    }

    function verifiedPhone(input) {
        var validRegex = '^[a-zA-Z0-9.%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)[a-zA-Z0-9-]+.[a-zA-Z0-9-.]{2,61}$';
        if (input.match(validRegex)) {
            return true;
        } else {
            return false;
        }

    }

    // const [phone, setPhone] = useState('')
    // const [otp, setOtp] = useState('');
    // const [final, setfinal] = useState('');

    // const [resCaptcha, setReCaptcha] = useState()


    // const sendOtp = () => {

    //     if (phone === "" || phone.length < 10) return;


    //     let verify = new firebase.auth.RecaptchaVerifier('recaptcha-container');
    //     auth.signInWithPhoneNumber(phone, verify).then((result) => {
    //         setfinal(result);
    //         console.log('result', result);
    //         alert("code sent")
    //         handleShow()
    //     })
    //         .catch((err) => {
    //             alert(err);
    //             window.location.reload()
    //         });
    // }

    // const ValidateOtp = () => {
    //     if (otp === null || final === null)
    //         return;
    //     final.confirm(otp).then((result) => {
    //         console.log('result----', result.user.accessToken);
    //     }).catch((err) => {
    //         alert("Wrong code");
    //     })
    // }
    function getexestingToken() {
        if (window.localStorage.getItem("token") != undefined || window.localStorage.getItem("token") != null) {
            navigate("/home")
        }
        else {
            navigate("/login")
        }
    }
    useEffect(() => {
        getexestingToken()
    }, [])
    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Login / Signup
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {show ? <>
                        <div className="otp-login">
                            <div className="mb-3">
                                <label htmlFor="MobileNumber" className="form-label">Login (Email)</label>
                                <input type="tel" className="form-control" id="MobileNumber" aria-describedby="emailHelp" name="entity" value={logIn.entity} onChange={handleLogin} />

                            </div>
                            <button type="button" onClick={handleClickOtp} className="btn btn-primary">GET OTP</button>
                        </div>
                    </> : <>
                        <div className="otp-login verify-otp">
                            <h2>Verify OTP</h2>
                            <p>Verify Business ID {logIn.entity} <Link to="#" onClick={() => handleShow()}>Change</Link></p>
                            <div className="mb-3">
                                <label htmlFor="MobileNumber" className="form-label">ENTER OTP</label>
                                <input type="tel" className="form-control" id="MobileNumber" aria-describedby="emailHelp" name="otp" value={otp.otp} onChange={handleOtp} />

                            </div>
                            <button type="button" onClick={otpVerify} className="btn btn-primary">Verify</button>
                        </div>
                    </>}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
            {/* <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Login / Signup
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                

                    {show ? <>
                        <div className="otp-login">
                            <div className="mb-3">
                                <label htmlFor="MobileNumber" className="form-label">Mobile Number</label>
                                <input type="tel" className="form-control" id="MobileNumber" aria-describedby="emailHelp" name="phone" value={phone} onChange={(e) => setPhone(e.target.value)} placeholder="+91" />
                                
                                <div id="recaptcha-container"></div>
                            </div>
                            <button type="button" onClick={sendOtp} className="btn btn-primary">GET OTP</button>
                        </div>
                    </> : <>
                        <div className="otp-login verify-otp">
                            <h2>Verify OTP</h2>
                            <p>Verify Mobile Number{phone}<Link to="#" onClick={() => handleShow()}>Change</Link></p>
                            <div className="mb-3">
                                <label htmlFor="MobileNumber" className="form-label">Enter OTP</label>
                                <input type="tel" className="form-control" id="MobileNumber" aria-describedby="emailHelp" name="otp" value={otp} onChange={(e) => setOtp(e.target.value)} />
    
                            </div>
                            <button type="button" onClick={ValidateOtp} className="btn btn-primary">Verify</button>
                        </div>
                    </>}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal> */}

        </>
    )
}
export default OtpLogin
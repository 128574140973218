import React, { useEffect, useState } from "react";
import fertilizer1 from "../../../../assets/img/products/1.jpg";
import Rating from "../../../../shared/rating/Rating";

import { FiSearch } from "react-icons/fi";
import { GrAdd } from "react-icons/gr";
import { BsFillCartFill } from "react-icons/bs";
import { BiLoaderAlt } from "react-icons/bi";

// You need to import the CSS only once
// import "react-awesome-lightbox/build/style.css";

import "./ProductItem.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ImgesData } from "../../proImgs/ImgesData";
import axios from "axios";
import {
  setCartLists,
  useOfflineAddPostMutation,
  useSetCartMutation,
} from "../../../products/productSlice";
import { useDispatch, useSelector } from "react-redux";

import { featuredDB } from "../../../../rki-data/category";

import tp1 from "../../../../assets/img/leatzmeat/product/portfolio_image1.jpg";
import tp2 from "../../../../assets/img/leatzmeat/product/tp-2.jpg";
import tp3 from "../../../../assets/img/leatzmeat/product/tp-3.jpg";
import tp4 from "../../../../assets/img/leatzmeat/product/tp-4.jpg";
import tp5 from "../../../../assets/img/leatzmeat/product/tp-5.jpg";
import { useTranslation } from "react-i18next";
import { base_url } from "../../../../server";

const productData = [
  { _id: "2", proUrl: tp2, title: "Chicken Curry Cut - Small Pieces" },
  { _id: "3", proUrl: tp3, title: "Afghani Murgh Seekh Kebab" },
  { _id: "4", proUrl: tp4, title: "Chicken Breast Boneless (Large Pack)" },
  { _id: "1", proUrl: tp1, title: "Chunky Shawarma Chicken Spread" },
  { _id: "5", proUrl: tp5, title: "Afghani Murgh Seekh Kebab" },
  { _id: "6", proUrl: tp1, title: "Chicken Breast Boneless" },
  // { _id: "7", proUrl: tp2, title: "Chicken Boneless - Mini Bites" },
  // { _id: "8", proUrl: tp5, title: "Chicken Boneless - Mini Bites" },
];

function ProductItem({
  loadMore,
  setTotalProductLength,
  latestData,
  setLatestData,
  handleShow,
}) {
  const userid = window.localStorage.getItem("user_id");
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);
  const [isLoading, SetIsloading] = useState(true);
  const token = window.localStorage.getItem("token");
  const getData = async (id) => {
    try {
      const res = await axios.get(
        `https://onlineparttimejobs.in/api/category/public`,
        {
          withCredentials: true,
          headers: {
            "content-type": "application/json; charset=UTF-8",
            authorization: `Bearer ${token}`,
          },
        }
      );
      setData(res.data);
      SetIsloading(false);
    } catch (error) {
      setError(true);
      SetIsloading(false);
    }
  };

  const [page, setPage] = useState(null);
  const pagination = async () => {
    try {
      const res = await axios.get(`${base_url}product/count`, {
        withCredentials: true,
      });
      let showPage = Math.floor(res?.data.count / 6);
      const reminder = Math.floor(res?.data.count % 6);

      if (reminder > 0) {
        showPage += 1;
      }

      const arru = [];
      for (let i = 0; i < showPage; i++) {
        arru.push({ page: i });
      }

      setPage(arru);
    } catch (error) {
      console.error("Error while fetching pagination data:", error);
      // Handle the error as needed, e.g., show an error message to the user
    }
  };

  const curr = window.localStorage.getItem("currencySym");
  let currencySymbol = curr;
  if (currencySymbol === "undefined") {
    currencySymbol = "ZK";
  }

  const params = useParams();
  useEffect(() => {
    if (!data) {
      getData(0);
    }
    pagination();

    if (params.val) {
      const filteredData = data?.filter((currentElm) => {
        const currentName = currentElm?.name?.toLowerCase();
        const currentVal = params.val.toLowerCase();

        if (currentName) {
          if (
            currentName.includes(currentVal) ||
            currentElm.brand === currentVal
          ) {
            return currentElm;
          }
        }
      });
      // setLatestData(filteredData);
      // setTotalProductLength(filteredData)
    } else {
      // setLatestData(data);
      // setTotalProductLength(data)
    }
  }, []);

  const [
    addToCart,
    {
      data: datacart,
      isLoading: isAddCartLoading,
      isSuccess: isAddToCartSuccess,
      isError: isAddToCartError,
    },
  ] = useSetCartMutation();
  const isLogin = window.localStorage.getItem("isLogin");
  const [
    postOffline,
    { data: resData, isSuccess, isError: offErr, isLoading: isloadPost },
  ] = useOfflineAddPostMutation();
  const navigate = useNavigate();

  const { updatedProducts: products } = useSelector((state) => {
    return state.productList;
  });

  useEffect(() => {
    if (isSuccess) {
      navigate("/cart");
    }
  }, [isSuccess]);

  const dispacher = useDispatch();
  useEffect(() => {
    if (isSuccess) {
      dispacher(setCartLists(resData.cart.products));
    }
  }, [isSuccess, offErr]);

  const { t } = useTranslation();

  return (
    <>
      {isLoading ? (
        <div className="loaderIcon">
          <BiLoaderAlt />
        </div>
      ) : null}
      {/* <CollectionFilter latestData={latestData} /> */}
      {data?.length === 0 && (
        <h6 className="text-center mb-5">No Found Data</h6>
      )}
      {data?.slice(0, loadMore).map((item, i) => {
        return (
          <div className="col-lg-4 col-md-6 col-sm-12" key={item._id}>
            <div className="featuredInfo sevi">
              <div className="featuredFigure">
                {isloadPost && (
                  <div className="preloaderCount">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                )}
                <div
                  className="featuredImg"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Link to={`/product/category/${item?.uid}/${item?.slug}`}>
                    <img
                      src={item?.icon?.url}
                      alt="Product"
                      className="imgProduct"
                    />
                  </Link>
                </div>
              </div>
              <div className="featuredContent">
                <h5>
                  <Link to={`/product/category/${item?.uid}/${item?.slug}`}>
                    {item?.name}
                  </Link>
                </h5>
              </div>
            </div>
          </div>
        );
      })}

      {error && (
        <div className="alertMsg mb-4" role="alert">
          <h4 style={{ color: "red" }}>Server Error</h4>
        </div>
      )}

      <nav aria-label="Page navigation example text-cneter">
        <ul className="pagination" style={{ justifyContent: "center" }}>
          {/* {page && page?.map((item) => {
            return <li className="page-item" onClick={() => getData(item.page)}><button className="page-link">{item?.page}</button></li>
          })} */}
          {/* <li className="page-item" onClick={() => getData(0)}><button className="page-link">1</button></li>
          <li className="page-item" onClick={() => getData(1)}><button className="page-link">2</button></li>
          <li className="page-item" onClick={() => getData(2)}><button className="page-link">3</button></li>
          <li className="page-item" onClick={() => getData(3)}><button className="page-link">4</button></li>
          <li className="page-item" onClick={() => getData(4)}><button className="page-link">5</button></li>
          <li className="page-item" onClick={() => getData(5)}><button className="page-link">6</button></li>
          <li className="page-item" onClick={() => getData(6)}><button className="page-link">7</button></li>
          <li className="page-item" onClick={() => getData(7)}><button className="page-link">8</button></li> */}
        </ul>
      </nav>
    </>
  );
}

export default ProductItem;

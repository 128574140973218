import React from "react";
import { Helmet } from "react-helmet";
import Blog from "../../components/blog/Blog";

function BlogPage({ setShow }) {

  return (
    <>
      <Helmet>
        <title>Blog | Robotics AI</title>
        <meta
          name="keyword"
          content="Fertilizer, Agricultural, Seeds, Machinery, Nutrition"
        />
        <meta
          name="description"
          content="Buy Agricultural Products and Machinery Online at ETG. We Offering broad range of Seeds, Plant Nutrition, Plant Protection and Agri Implements."
        />
      </Helmet>
      <Blog />
    </>
  );
}

export default BlogPage;

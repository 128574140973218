import React from "react";

import a1 from '../../../assets/img/leatzmeat/about-chicken.png'
import { TiTick } from "react-icons/ti";
function AboutStory({ data }) {
  return (
    <>
      <div className="aboutSection p-30">
        <div className="container">

          <div className="row">
            <div className="col-lg-6">
              <div className="image">
                <img src='https://dmassets.hyundai.com/is/image/hyundaiautoever/hyundai-ioniq-5-n-debut-goodwood-2023-road-exterior-front-01:Featured%20Content%20Teasers%20Desktop?wid=572&hei=332' alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="containt-about">
                <div class="sec-title">
                  <span>About Us</span>
                  <h2>ABOUT ORACLE ROBOTICS AI</h2>
                </div>
                <div class="text-about">
                  <p>In recent years, there has been an exponential growth in startups focusing on Robotics and Artificial Intelligence (AI). These groundbreaking technologies have become crucial in shaping the future of various industries, from healthcare to manufacturing and beyond. One innovative company that has caught the attention of industry experts is Oracle Robotics AI.</p>
                  <p>Oracle Robotics AI is at the forefront of the Robotics and AI revolution, with a clear vision to provide scalable and reliable solutions for industries, hotels, tourism, and households. To achieve this, the company has partnered with leaders in the AI and Robotics industry. These strategic alliances enable Oracle Robotics AI to leverage the latest technology and expertise, ensuring their offerings remain cutting-edge.  .</p>
                </div>
                <ul class="list-1 clearfix">
                  {/* <li><TiTick className="icon-1" />100% Organic Meat</li> */}
                  <li><TiTick className="icon-1" />Payment Securation</li>
                </ul>
              </div>
            </div>
          </div>
          {/* <h3 className="subtitle">OUR STORY</h3>
          <p className="lead">
            {data?.about_company}
          </p> */}
          {/* <p>
            Our passion lies in the upliftment of farming communities; an unhindered commitment since establishment. Our promoters identified three challenges that supply chains in Africa faced and created a globalized business model around solutions addressing these obstacles.
          </p> */}

        </div>
      </div>
    </>
  );
}

export default AboutStory;

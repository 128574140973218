
import axios from 'axios';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useParams } from 'react-router-dom';

function QuestionAnswer(props) {
    const params = useParams()
    const [state, setState] = useState({
        subject: "",
        detail: '',
        product_id: params?.uid
    })
    const handler = (e)=>{
        const clone = {...state}
        clone[e.target.name] = e.target.value
        setState(clone)
    }

    const postQuestion = async ()=>{
        try {
            const res = await axios.post(`https://onlineparttimejobs.in/api/product/question/add_question`,state,{
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    Authorization: `Bearer ${window.localStorage.getItem('token')}`,
                },
            })
            alert('Question Send . You can See if Admin Publish Your Question')
            // props.getData()
            props.onHide()
        } catch (error) {
            alert('Question Send Fail!')
        }
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Ask  Question
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form>
                    <div className="mb-3">
                        <label htmlFor="exampleInputEmail1" className="form-label">Subject</label>
                        <input type="text" className="form-control" name='subject' value={state.subject} onChange={handler} id="exampleInputEmail1" aria-describedby="emailHelp" />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="exampleInputPassword1" className="form-label">Detail</label>
                        <input type="text" className="form-control" name='detail' value={state.detail} onChange={handler}  id="exampleInputPassword1" />
                    </div>
                    <button type="button" onClick={postQuestion} className="btn btn-primary">Submit</button>
                </form>

            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default QuestionAnswer
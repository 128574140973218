import axios from "axios"
import { useEffect } from "react"
import { useState } from "react"
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io"
import { Link, NavLink, useParams } from "react-router-dom"


function NavigationBars() {
    const [data, setdata] = useState(null)
    const params = useParams()
    const token = window.localStorage.getItem('token')
    const getData = async () => {
        const res = await axios.get(`https://onlineparttimejobs.in/api/category/public`, {
            withCredentials: true,
            headers: {
                "content-type": "application/json; charset=UTF-8",
                authorization: `Bearer ${token}`,
            },
        })
        setdata(res.data)
    }

    useEffect(() => {
        getData()
    }, [])
    const [margin, setMargin] = useState(0)
    const IncreseMargin = () => {
        setMargin(margin - 200)
    }
    const DecreaseMarging = () => {
        setMargin(margin + 200)
    }
    return <div className="container" style={{ position: "sticky", top: 0, zIndex: "3", backgroundColor: "white", borderBottom: "1px solid #c4c4c4" }}>
        <div className="row mainrows">
            <div className="container deepOrange pullRightLeft" style={{}}>
                <div className="maintainmargin" style={{ marginLeft: margin }}>
                    {data && data?.map((item, i) => {
                        return <NavLink className={`${params.id === item?.uid && 'active'}`} to={`/product/category/${item?.uid}/${item?.slug}`} >
                            {item?.name}
                        </NavLink >
                    })}
                </div>
                {margin !== -1000 && <div className="arrowClas" onClick={IncreseMargin}>
                    <IoIosArrowForward />
                </div>}
                {margin !== 0 && <div className="arrowClasleft" onClick={DecreaseMarging}>
                    <IoIosArrowBack />
                </div>}

            </div>


        </div>
    </div>
}
export default NavigationBars
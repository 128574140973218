import React from "react";

function ContactMap() {
  return (
    <div style={{display:"flex" ,justifyContent:"center"}}>
      <img width={1200} src="https://oraclerobotics.files.wordpress.com/2023/09/our-presnce-global.jpg" />
      {/* <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3782.6467968070324!2d73.90835216460451!3d18.544856637394904!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c10d3fa8540f%3A0x4a7974e90b67294!2sCerebrum%20IT%20Park%20-%20B3!5e0!3m2!1sen!2sin!4v1675858792717!5m2!1sen!2sin"
        width="100%"
        height="450"
        id="frame1" 
        allowFullScreen
      ></iframe> */}
    </div>
  );
}

export default ContactMap;

import React, { useEffect, useState } from "react";
import { useContactMessageMutation } from "../../products/productSlice";
import { Form } from "react-bootstrap";
import axios from "axios";
import Select from "react-select";

function ContactForm() {
  const [state, setState] = useState({
    firstname: "",
    lastname: "",
    email: "",
    message: "",
    category: "",
    // subCategory: "",
    phone: ""
  })
  const token = window.localStorage.getItem('token')
  const [sendDataContact, { isLoading }] = useContactMessageMutation()
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [subcategory, setsubcategory] = useState()

  const getSubCategory = async (id) => {
    const arrid = []
    for (let i = 0; i < selectedOptions.length; i++) {
      const element = selectedOptions[i];
      arrid.push(element._id)
    }
    try {
      const res = await axios.post(`https://onlineparttimejobs.in/api/category/public/child`, { ids: arrid }, {
        withCredentials: true,
        headers: {
          "content-type": "application/json; charset=UTF-8",
          authorization: `Bearer ${token}`,
        },
      })
      setsubcategory(res.data)
    } catch (error) {

    }
  }
  const chengehandle = (e) => {
    const clone = { ...state }
    clone[e.target.name] = e.target.value
    setState(clone)
  }

  const [data, setData] = useState()

  const getData = async (id) => {
    try {
      const res = await axios.get(`https://onlineparttimejobs.in/api/category/public`, {
        withCredentials: true,
        headers: {
          "content-type": "application/json; charset=UTF-8",
          authorization: `Bearer ${token}`,
        },
      })
      const maped = res?.data?.map((item) => {
        return { ...item, value: item?.name, label: item?.name }
      })
      setData(maped)

    } catch (error) {

    }
  }
  useEffect(() => {
    getData()
  }, [])

  const sendData = async () => {
    const arrid = []
    for (let i = 0; i < selectedOptions.length; i++) {
      const element = selectedOptions[i];
      arrid.push(element.uid)
    }
    try {
      const res = await axios.post(`https://onlineparttimejobs.in/api/contactMessage/add_ContactMessage`, { ...state, category: arrid }, {
        withCredentials: true,
        headers: {
          "content-type": "application/json; charset=UTF-8",
          authorization: `Bearer ${token}`,
        },
      })
      alert('Send Successfully')
    } catch (error) {
      alert('Send Fail')
    }
  }




  const handleSelectChange = (selectedValues) => {
    setSelectedOptions(selectedValues);
  };
  useEffect(() => {
    if (selectedOptions?.length) {
      // getSubCategory()
    }
  }, [selectedOptions])
  return (
    <>
      <section className="contactForm p-30">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 conatct-msg-2">
              <div className="conatct-msg">
                {isLoading && <div className="preloaderCount">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>}
                <h4 className="mt-6 mb-4">Send Us a Message</h4>
                <form className="mb-0" action="#">
                  <div className="form-group mb-3">
                    <label className="mb-1" htmlFor="contact-name">
                      First Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="contact-name"
                      name="firstname"
                      onChange={chengehandle}
                      value={state.firstname}
                      required
                      placeholder="Enter First Name"
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label className="mb-1" htmlFor="contact-name">
                      Last Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="contact-name"
                      name="lastname"
                      onChange={chengehandle}
                      value={state.lastname}
                      required
                      placeholder="Enter Last Name"
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label className="mb-1" htmlFor="contact-email">
                      E-mail (Plese fill active mail Id)
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="contact-email"
                      name="email"
                      onChange={chengehandle}
                      value={state.email}
                      required
                      placeholder="Enter Email"
                    />
                  </div>
                  <div className="form-group mb-3">
                    <label className="mb-1" htmlFor="contact-email">
                      Phone
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="contact-email"
                      name="phone"
                      onChange={chengehandle}
                      value={state.phone}
                      required
                      placeholder="Phone number"
                    />
                  </div>


                  <div className="form-group mb-3">
                    <label className="mb-1" htmlFor="contact-email">
                      Category
                    </label>
                    <Select
                      options={data}
                      isMulti
                      value={selectedOptions}
                      onChange={handleSelectChange}
                    />

                  </div>





                  {/* <div className="form-group mb-3">
                    <label className="mb-1" htmlFor="contact-email">
                      Sub Category
                    </label>
                    <Form.Select aria-label="Default select example" disabled={subcategory?.length == 0} name="subCategory" onChange={chengehandle} value={state.subCategory}>
                      <option>Open this select menu</option>
                      {subcategory && subcategory?.map((item) => {
                        return <option value={item.uid}>{item.name}</option>
                      })}

                    </Form.Select>
                  </div> */}

                  {/* <div className="form-group mb-3">
                    <label className="mb-1" htmlFor="contact-email">
                      Sub-Category
                    </label>
                    <Form.Select aria-label="Default select example">
                      <option>Open this select menu</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </Form.Select>
                  </div> */}






                  <div className="form-group mb-3">
                    <label className="mb-1" htmlFor="contact-message">
                      Your Message
                    </label>
                    <textarea
                      cols={30}
                      rows={4}
                      id="contact-message"
                      className="form-control"
                      name="message"
                      required
                      onChange={chengehandle}
                      value={state.message}
                      defaultValue={""}
                      placeholder="Enter Your Message"
                    />
                  </div>
                  <div className="form-footer mb-0">
                    <button
                      type="button"
                      className="btn btn-dark font-weight-normal"
                      onClick={sendData}
                    // disabled={!state.email || !state.name || !state.message}
                    >
                      Send Message
                    </button>
                  </div>
                </form>
              </div>
              {/* <div className="col-lg-6">
              <h4 className="mt-6 mb-4">Frequently Asked Questions</h4>

              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Accordion Item #1
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <strong>This is the first item's accordion body.</strong>{" "}
                      It is shown by default, until the collapse plugin adds the
                      appropriate classes that we use to style each element.
                      These classes control the overall appearance, as well as
                      the showing and hiding via CSS transitions. You can modify
                      any of this with custom CSS or overriding our default
                      variables. It's also worth noting that just about any HTML
                      can go within the <code>.accordion-body</code>, though the
                      transition does limit overflow.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      Accordion Item #2
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <strong>This is the second item's accordion body.</strong>{" "}
                      It is hidden by default, until the collapse plugin adds
                      the appropriate classes that we use to style each element.
                      These classes control the overall appearance, as well as
                      the showing and hiding via CSS transitions. You can modify
                      any of this with custom CSS or overriding our default
                      variables. It's also worth noting that just about any HTML
                      can go within the <code>.accordion-body</code>, though the
                      transition does limit overflow.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      Accordion Item #3
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <strong>This is the third item's accordion body.</strong>{" "}
                      It is hidden by default, until the collapse plugin adds
                      the appropriate classes that we use to style each element.
                      These classes control the overall appearance, as well as
                      the showing and hiding via CSS transitions. You can modify
                      any of this with custom CSS or overriding our default
                      variables. It's also worth noting that just about any HTML
                      can go within the <code>.accordion-body</code>, though the
                      transition does limit overflow.
                    </div>
                  </div>
                </div>
              </div>

             
            </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ContactForm;

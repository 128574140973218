import React from "react";
import { Link } from "react-router-dom";
import pageHeaderBg from "../../../assets/img/leatzmeat/about-img.jpg";

function AboutBanner() {
  return (
    <>
      <section
      
        className="aboutBanner p-30"
        style={{ background: `url(https://s7g10.scene7.com/is/image/hyundaiautoever/170914_nhm_hyu_6631_39L:21x7?wid=1920&hei=640&fit=wrap,1)` ,height:"460px"}}
      >
        <div className="container" style={{height:"100%",display:"flex",alignItems:"end"}}>
          <div className="aboutBannerInfo" >
            <h6 style={{color:"white"}}>ABOUT US</h6>
            <h2>OUR COMPANY</h2>
            <Link to="/contact" className="btn btn-dark">
              Contact
            </Link>
          </div>
        </div>
      </section>
    </>
  );
}

export default AboutBanner;

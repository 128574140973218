function createMarkup(data) {
  return { __html: data };
}
function ContenstOrecal({ details }) {
  console.log("details", details);

  function getYouTubeVideoId(url) {
    // Regular expression to match YouTube video ID
    const regex =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

    // Execute the regular expression and extract the video ID
    const match = url.match(regex);

    // Check if a match is found and return the video ID
    if (match && match[1]) {
      return match[1];
    } else {
      // Return null if no match is found
      return null;
    }
  }
  return (
    <div className="contentSection">
      {/* <h1>Technology with a human heart.</h1> */}
      <div className="tabs_item">
        <div
          className="products-details-tab-content"
          dangerouslySetInnerHTML={createMarkup(details?.description)}
        />
      </div>
      <div className="videoWrapper">
        {!!details?.video_link && (
          <div className="video-container">
            <iframe
              width="100%"
              height="315"
              src={`https://www.youtube.com/embed/${getYouTubeVideoId(
                details?.video_link
              )}`}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
        )}
        <div
          className="products-details-tab-content"
          dangerouslySetInnerHTML={createMarkup(details?.description)}
        />
      </div>
    </div>
  );
}
export default ContenstOrecal;

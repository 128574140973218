import React, { useEffect, useState } from "react";
import { useGetCategoriesQuery, useGetMenuListQuery } from "../../products/productSlice";

import "./ProductsCategory.css";
import { productData } from "../../../pages/product-detail/mockData";

import s1 from '../../../assets/img/leatzmeat/service/s1.png'
import s2 from '../../../assets/img/leatzmeat/service/s2.png'
import s3 from '../../../assets/img/leatzmeat/service/s3.png'
import s4 from '../../../assets/img/leatzmeat/service/s4.png'

import { featuredDB } from "../../../rki-data/category";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

function ProductsCategory() {
  // const { data, isLoading, error } = useGetCategoriesQuery();
  // console.log('category---', data)

  const [data, setdata] = useState(null)

  const token = window.localStorage.getItem('token')
  const getData = async () => {
    const res = await axios.get(`https://onlineparttimejobs.in/api/per/category`, {
      withCredentials: true,
      headers: {
        "content-type": "application/json; charset=UTF-8",
        authorization: `Bearer ${token}`,
      },
    })
    setdata(res.data)
  }

  useEffect(() => {
    getData()
  }, [])


  const categories = {
    title: "Shop by categories",
    subTitle: "Freshest meats and much more!",
    categData: [
      { id: "1", paragraph: "Quam nihil molestiae conseua illum rui dolorem eum fugiat auo volutas nulla pariatur..." },
      { id: "2", paragraph: "Quam nihil molestiae conseua illum rui dolorem eum fugiat auo volutas nulla pariatur..." },
      { id: "3", paragraph: "Quam nihil molestiae conseua illum rui dolorem eum fugiat auo volutas nulla pariatur..." },
      { id: "4", paragraph: "Quam nihil molestiae conseua illum rui dolorem eum fugiat auo volutas nulla pariatur..." },

    ]
  }
  const { t } = useTranslation()
  // console.log(data);
  return (
    <>
      <section className="productsCategorySec" style={{ position: "sticky", top: 0 ,zIndex:"3" ,backgroundColor:"white" }}>
        <div className="container">
          <div className="row justify-content-center">
            {/* <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="fisherman-content fisherman-content-2 mb-3">
                <h3>{t('FEATURED CATEGORIES')}</h3>
                <h2 className="text_1">{t('Lorem ipsum dolor sit amet consectetur adipisicing elit')}!</h2>
              </div>
            </div> */}

            {/* {isLoading && <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>}
            {error && <h4 style={{ textAlign: "center", color: "red" }}>Server Error</h4>} */}
            {/* {console.log(data)} */}
            {data && data?.map((item, i) => {
              return <div key={item?.title?._id} className="col-lg-3 col-md-6 col-sm-12">
                <div className="productsCategoryItem">
                  {/* <div className="categoryHeader">
                    <h5 className="title">{item.catTitle}</h5>
                    <Link to={`/product/category/${item._id}`}>view all </Link>
                  </div> */}
                  <div className="categoryBody">
                    {/* <div className="singleProducts singleProducts-2">
                      <Link className="single-link" to={`/product/category/${item?.title?._id}`} >
                        {
                          item?.url ? <img src={productData.data[1].bigUrl} alt="Product" /> : <img src={categories?.categData[i]?.imgUrl} alt="Product" />
                        }
                        <p> Flower seeds</p>
                        <span className="sale">Sale</span>
                      </Link>
                    </div> */}
                    <div className={`categoryHeader  ${i == 0 && "differentbackground"}`}>
                      <Link to={`/product/category/${item?.categ?.uid}/${item?.categ?.slug}`} >
                        <h5 className={`title ${i == 0 && `differentbackground`}`}>{item?.categ?.name}</h5>
                        {/* <h5 className="title">{item?.catTitle}</h5> */}
                       
                        {/* <Link to={`/product/category/${item._id}`}>view all </Link> */}
                      </Link >
                    </div>
                    {/* <div className="singleProducts">
                      <a href="/products">
                        <img src={productData.data[2].bigUrl} alt="Product" />
                        <p> Flower seeds</p>
                      </a>
                    </div> */}
                    {/* <div className="singleProducts">
                      <a href="/products">
                        <img src={productData.data[3].bigUrl} alt="Product" />
                        <p> Flower seeds</p>
                      </a>
                    </div> */}
                    {/* <div className="singleProducts">
                      <a href="/products">
                        <img src={productData.data[1].bigUrl} alt="Product" />
                        <p> Flower seeds</p>
                      </a>
                    </div> */}
                  </div>
                </div>
              </div>
            })}
          </div>
        </div>
      </section>
    </>
  );
}

export default ProductsCategory;


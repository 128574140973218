import React, { useEffect, useState } from "react";
import AboutBanner from "./about-banner/AboutBanner";
import Breadcrumb from "../../shared/breadcrumb/Breadcrumb";
import AboutStory from "./about-story/AboutStory";
import AboutChoose from "./about-choose/AboutChoose";
import AboutCounters from "./about-counters/AboutCounters";
import axios from "axios";
import { base_url } from "../../server";

function About() {

  const [data, setData] = useState()
  const aboutData = async () => {
    try {
      const res = await axios.get(`${base_url}adminWeb_footer/get`)
      setData(res.data)
    } catch (error) {
      alert('server-error')
    }

  }
  useEffect(() => {
    // aboutData()
  }, [])

  return (
    <>
      <Breadcrumb title="About" />
      <main className="main about">
        <AboutBanner />
        <AboutStory data={data} />
        <AboutChoose />
        <AboutCounters />
      </main>
    </>
  );
}

export default About;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa";
import { BsTwitter, BsInstagram, BsWhatsapp } from "react-icons/bs";
import { GoLocation } from "react-icons/go";
import { MdCall } from "react-icons/md";
import { HiMail } from "react-icons/hi";
import "./Footer.css";
import axios from "axios";
import footerImg from "../../assets/img/roboticsAiImage/oracleRoboticsFull-Logo.jpeg";
import app1 from "../../assets/img/appleStore.png";
import app2 from "../../assets/img/leatzmeat/play.png";
import DynemicPopup from "./DynemicPopup";

function Footer() {
  const [popups, setPopups] = useState(null);
  const [state, setState] = useState({
    about_company: "",
    office_address: "",
    location: "",
    phoneNo: "",
    email: "",
  });

  const [modalShow, setModalShow] = useState(false);
  const getData = async () => {
    const res = await axios.get(
      `https://onlineparttimejobs.in/api/adminWeb_footer/get`
    );
    setState({
      about_company: res.data.about_company,
      office_address: res.data.office_address,
      location: res.data.contact_info.location,
      phoneNo: res.data.contact_info.phoneNo,
      email: res.data.contact_info.email,
    });
    window.localStorage.setItem("callNum", res.data.contact_info.phoneNo);
  };
  const getData2 = async () => {
    try {
      const res = await axios.get(
        `https://onlineparttimejobs.in/api/popup/public`,
        {
          withCredentials: true,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          },
        }
      );
      setPopups(res.data);
      if (res.data?.page) {
        setModalShow(true);
      }
    } catch (error) {}
  };

  useEffect(() => {
    // getData()
    getData2();
  }, []);

  return (
    <>
      {/* Start Footer Section */}
      {/* {modalShow && <DynemicPopup show={modalShow} onHide={() => setModalShow(false)} popups={popups} />} */}
      <section className="footer-section footer-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="aboutCompanyText">
                <h3>TECHNOLOGY WITH A HUMAN HEART.</h3>
                <p>
                  Wearable exoskeletons, driverless vehicles, all-terrain rovers
                  – at Hyundai, we believe the future of mobility will involve
                  more than just passenger cars. We are devoted to the research
                  and development of robots based on our vision “Progress for
                  Humanity". {/* {state?.about_company} */}
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <div className="footer-imag mb-2">
                  <img src={footerImg} alt="" width={175} />
                </div>
                <div className="app-option">
                  <div className="footer-heading">
                    <h3>KEEP IN TOUCH ON</h3>
                    <a href="#">
                      <img src={app1} alt="" />
                    </a>
                    <a href="#">
                      <img src={app2} alt="" />
                    </a>
                  </div>
                </div>
                <div className="footer-heading">
                  <h3>Office Address</h3>
                </div>

                <p>
                  Financial Center Dubai (UAE)
                  {/* {state?.office_address} */}
                </p>
                <ul className="footer-social">
                  <li>
                    <a href="https://mmslfashions.in/" className="facebook">
                      <FaFacebookF />
                    </a>
                  </li>

                  <li>
                    <a href="https://mmslfashions.in/" className="twitter">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-twitter-x"
                        viewBox="0 0 16 16"
                      >
                        <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z" />
                      </svg>
                    </a>
                  </li>

                  <li>
                    <a href="https://mmslfashions.in/" className="instagram">
                      <BsInstagram />
                    </a>
                  </li>

                  <li>
                    <a href="https://mmslfashions.in/" className="whatsapp">
                      <BsWhatsapp />
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <div className="footer-heading">
                  <h3>USEFUL LINKS</h3>
                </div>
                <ul className="footer-quick-links">
                  <li>
                    <Link to="/about"> About</Link>
                  </li>
                  <li>
                    <Link to="/contact"> Contact</Link>
                  </li>
                  <li>
                    <Link to="/terms-and-conditions">
                      {" "}
                      Terms and Conditions
                    </Link>
                  </li>
                  <li>
                    <Link to="/refund_returns">Refund Policy</Link>
                  </li>
                  <li>
                    <Link to="/shipping-policy">Shipping Policy</Link>
                  </li>
                  <li>
                    <Link to="/blog"> Blog</Link>
                  </li>
                  {/* <li>
                    <Link to="/photo-gallery"> Photo Gallery</Link>
                  </li> */}
                  <li>
                    <Link to="/faq">FAQ</Link>
                  </li>
                  <li>
                    <Link to="/testimonial">Testimonial</Link>
                  </li>
                  <li>
                    <Link to="/view-all-brands">View-All-Brands</Link>
                  </li>
                  {/* <li>
                    <Link to="/pickup_point_store">Pickup Point Store List</Link>
                  </li> */}
                  {/* <li>
                    <Link to="/affiliate_register">Affiliate Register</Link>
                  </li> */}
                  <li>
                    <Link to="/our_people">Our Peoples</Link>
                  </li>

                  <li>
                    <Link to="/all_categories">All Categories</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <div className="footer-heading">
                  <h3>Contact info</h3>
                </div>

                <div className="footer-info-contact">
                  <div className="topIcon">
                    <GoLocation />
                    <span>
                      <h3>Location</h3>
                      {/* {state?.location} */}
                      Financial Center Dubai (UAE)
                      {/* H No - 239, Sector 16 B Vasundhara Ghaziabad, UP -201012 */}
                    </span>
                  </div>
                </div>
                <div className="footer-contact">
                  <div className="footer-info-contact">
                    <div className="topIcon">
                      <MdCall />
                      <span>
                        <h3>Call Us</h3>
                        <a href={`tel:${state?.phoneNo}`}> {state?.phoneNo}</a>
                      </span>
                    </div>
                  </div>

                  <div className="footer-info-contact">
                    <div className="topIcon">
                      <HiMail />
                      <span>
                        <h3>Email Us</h3>
                        <a href={`mailto:${state?.email}`}>
                          <span className="__cf_email__">{state?.email}</span>
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-footer-widget">
                <div className="footer-heading">
                  <h3>Newsletter</h3>
                </div>
                <p>
                  Sign up & get shopping vouchers & stay updated about latest
                  styles and exclusive promotions
                </p>

                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="single-footer-widget">
                    <h5 style={{ color: "black" }}>Seller</h5>
                    <ul className="footer-quick-links sellerLists">
                      <li>
                        <Link to="/allSeller">Seller List</Link>
                      </li>
                      <li>
                        <Link to="/seller/login">Login</Link>
                      </li>
                      <li>
                        <Link to="/seller/sign-Up">Sign Up</Link>
                      </li>
                    </ul>
                  </div>
                </div>

                <form className="newsletter-form">
                  <input
                    type="email"
                    className="input-newsletter"
                    placeholder="Enter your email"
                    name="EMAIL"
                    required
                    autoComplete="off"
                  />
                  <button type="submit">
                    Subscribe Now
                    <i className="flaticon-plus"></i>
                  </button>
                  <div id="validator-newsletter" className="form-result"></div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="copyright-area">
          <div className="container">
            <div className="copyright-area-content">
              <div className="copyright-left">
                <p>
                  Copyright @
                  <script
                    data-cfasync="false"
                    src="/cdn-cgi/scripts/5c5dd728/cloudflare-static/email-decode.min.js"
                  ></script>
                  <script>document.write(new Date().getFullYear())</script> All
                  Rights Reserved.
                </p>
              </div>

              <div className="copyright-right">
                <ul>
                  <li>
                    <Link to="/terms-of-use">Terms & Conditions</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Footer Section */}
    </>
  );
}

export default Footer;

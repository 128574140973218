import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import brandLogo from "../../../assets/img/roboticsAiImage/oracleRoboticsFull-Logo.jpeg";
import { Link, useNavigate } from "react-router-dom";
import { BiCategory, BiPhoneCall } from "react-icons/bi";
import {
  AiOutlineUser,
  AiOutlineHeart,
  AiOutlineCloseCircle,
} from "react-icons/ai";
import { GoLocation } from "react-icons/go";
import { BsClockHistory, BsHandbag } from "react-icons/bs";
import { CiUser } from "react-icons/ci";
import { BiSearch } from "react-icons/bi";
import { FaBars, FaHistory } from "react-icons/fa";
import "./SearchBox.css";
import { useRef } from "react";
import {
  useGetCartQuery,
  getProducts,
  setUpdatedProducts,
  setObjectVal,
  useGetWishListQuery,
  setCartCalc,
  setWishCalc,
  setCartLeng,
} from "../../../components/products/productSlice";
import axios from "axios";
import DeliveryLocation from "../../../components/deleveryLocation/DeleveryLocation";

import cat1 from "../../../assets/img/leatzmeat/category/Todays_Deal_1.webp";
import s1 from "../../../assets/img/leatzmeat/service/s1.png";
import s2 from "../../../assets/img/leatzmeat/service/s2.png";
import s3 from "../../../assets/img/leatzmeat/service/s3.png";
import s4 from "../../../assets/img/leatzmeat/service/s4.png";
import s5 from "../../../assets/img/leatzmeat/service/s5.png";
import s6 from "../../../assets/img/leatzmeat/service/s6.png";
import s7 from "../../../assets/img/leatzmeat/service/s7.png";
import s8 from "../../../assets/img/leatzmeat/service/s8.png";
import s9 from "../../../assets/img/leatzmeat/service/s9.png";
import s10 from "../../../assets/img/leatzmeat/service/s10.png";
import s11 from "../../../assets/img/leatzmeat/service/s11.png";
import s12 from "../../../assets/img/leatzmeat/service/s12.png";
import { AiOutlineLogout } from "react-icons/ai";

function SearchBox({ showMenu, val }) {
  const [modalShow, setModalShow] = useState(false);
  const userid = window.localStorage.getItem("user_id");
  const isLogin = window.localStorage.getItem("isLogin");
  const nums = window.localStorage.getItem("callNum");
  const [click, setClick] = useState(false);

  const { data, isSuccess, isFetching } = useGetCartQuery(userid);

  const [show, setShow] = useState(false);

  const navigate = useNavigate();
  const { data: value } = useGetWishListQuery(userid);
  const [trendSearch, setTrendSearch] = useState(null);
  const inputRef = useRef();
  // console.log(inputRef.current.value, 'inputRef---');
  const handleRef = useRef();
  const dispatch = useDispatch();

  const [valuewish, setValuewish] = useState();

  const getDatas = async () => {
    try {
      const res = await axios.get(
        `https://onlineparttimejobs.in/api/user/wishlist`,
        {
          withCredentials: true,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(setWishCalc(res.data.length));
    } catch (error) {}
    if (!token) {
      return;
    }
    try {
      const res2 = await axios.get(`https://onlineparttimejobs.in/api/cart`, {
        withCredentials: true,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(setCartLeng(res2.data?.cart?.products.length));
    } catch (error) {}
  };

  useEffect(() => {
    getDatas();
  }, []);

  const [state, setState] = useState();

  const onSearch = (onSearch) => {
    handleRef(onSearch);
  };

  const handleShow = (a) => {
    setShow(!show);
  };

  const { updatedProducts, products, cartLengh } = useSelector((item) => {
    return item.productList;
  });

  useEffect(() => {
    if (data) {
      dispatch(getProducts(data?.cart?.products));
      dispatch(setObjectVal(data));
      if (isLogin === "true") {
        // dispatch(setUpdatedProducts(data.cart))
      }
      dispatch(setCartCalc(data));
    }
  }, [data]);

  const handleClick = () => {
    let currentVal = inputRef.current.value.toLowerCase();
    const res = axios.get(
      `https://onlineparttimejobs.in/api/product/search/${inputRef.current.value}`,
      { withCredentials: true }
    );
    navigate(`/products/${currentVal}`);
    currentVal = "";
    setShow(false);
  };

  const getData = async () => {
    const resData = await axios.get(
      `https://onlineparttimejobs.in/api/product/trendingSearches`,
      { withCredentials: true }
    );
    setTrendSearch(resData?.data);
  };
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (!handleRef.current) {
      return;
    }
    let handler = (e) => {
      if (show === true) {
        if (!handleRef?.current?.contains(e.target)) {
          setShow(false);
        }
      }
    };
    document.addEventListener("mousedown", handler);
    return function () {};
  }, []);

  useEffect(() => {
    if (isLogin === "true") {
      setState(data);
    } else {
      setState([]);
    }
  }, [isFetching]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleClick();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setModalShow(true);
    }, 2000);
  }, []);

  const token = window.localStorage.getItem("token");
  const [catData, setCatData] = useState(null);

  const getDataa = async () => {
    const res = await axios.get(
      `https://onlineparttimejobs.in/api/category/public`,
      {
        withCredentials: true,
        headers: {
          "content-type": "application/json; charset=UTF-8",
          authorization: `Bearer ${token}`,
        },
      }
    );
    setCatData(res.data);
  };

  useEffect(() => {
    getDataa();
  }, []);

  const onLogout = () => {
    window.localStorage.clear();
    navigate("/login");
  };

  const categories = {
    title: "Shop by categories",
    subTitle: "Freshest meats and much more!",
    categData: [
      { id: "1", imgUrl: s1, catTitle: "Today Deals" },
      { id: "2", imgUrl: s2, catTitle: "chiken" },
      { id: "3", imgUrl: s3, catTitle: "Fish & Seafood" },
      { id: "4", imgUrl: s4, catTitle: "Mutton" },
      { id: "6", imgUrl: s6, catTitle: "Parwans" },
      { id: "5", imgUrl: s5, catTitle: "Ready to Cooks" },
      { id: "7", imgUrl: s7, catTitle: " Eggs" },
      { id: "8", imgUrl: s8, catTitle: " Spread" },
      { id: "9", imgUrl: s9, catTitle: " Cold Cuts" },
      { id: "10", imgUrl: s10, catTitle: " Biryani & Kebab" },
      { id: "11", imgUrl: s11, catTitle: " Plant Based Meat" },
      { id: "12", imgUrl: s12, catTitle: "Meat Masala" },
    ],
  };

  return (
    <>
      <section className="searchSec">
        <div className="container">
          <div className="searchItem">
            <div className="logo">
              <Link to="/">
                {/* <img src={val?.icon?.url ? val?.icon?.url : brandLogo} alt="logo" /> */}
                <img src={brandLogo} alt="" />
              </Link>
              <h3
                style={{
                  fontWeight: 600,
                  fontFamily: "monospace",
                }}
              >
                Oracle Robotics AI
              </h3>
              <div className="bar">
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <FaBars onClick={showMenu} />
                </button>
              </div>
            </div>
            {/* <div className="">
              <button variant="primary" onClick={() => setModalShow(true)} className="jss5"><GoLocation />Select Delivery Location</button>
              <DeliveryLocation
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
            </div> */}
            <div className="searchSec">
              {/* <div className="searchInf">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                  onClick={handleShow}
                  ref={inputRef}
                  onKeyDown={handleKeyDown}
                />
                <div className="rightSearchInfo">
                  <div className="allCategory">
                    <select defaultValue="all category">
                      <option value="All Category">All Category</option>
                      <option value="Seeds">Seeds</option>
                      <option value="Crop Protection">
                        Crop Protection
                      </option>
                      <option value="Crop Nutrition">
                        Crop Nutrition
                      </option>
                      <option value="Machinery">Machinery</option>
                    </select>
                  </div>
                  <div className="searchIcon">
                    <BiSearch onClick={handleClick} />
                    
                  </div>
                </div>
              </div> */}

              {show && (
                <div className="treandingSec" id="DropShow" ref={handleRef}>
                  <div className="trendingItem">
                    <h5 className="trendingTitle">Trending Robbots</h5>
                    <ul>
                      {trendSearch &&
                        trendSearch?.map((item, i) => {
                          // console.log('item', item);
                          return (
                            <li key={i}>
                              <Link
                                to={`/products/${item.query}`}
                                className="nav-link"
                                onClick={() => onSearch(item.query)}
                              >
                                {" "}
                                {item.query}
                              </Link>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                  {/* <div className="trendingItem">
                    <h5 className="trendingTitle">Discover more</h5>
                    <ul>
                      <li>
                        <Link to="/">INSECTICIDES</Link>
                      </li>
                      <li>
                        <Link to="/">FUNGICIDES</Link>
                      </li>
                      <li>
                        <Link to="/">HERBICIDES</Link>
                      </li>
                      <li>
                        <Link to="/">BACTERICIDES</Link>
                      </li>
                      <li>
                        <Link to="/">ACARICIDES/MITICIDES</Link>
                      </li>
                    </ul>
                    <AiOutlineCloseCircle className="closeBtnDrop" onClick={handleShow} />
                  </div> */}
                </div>
              )}
            </div>

            <div className="category d-none">
              <div className="cate">
                <BiCategory />
                Categories
              </div>
              <div className="CATEGORYIE">
                <ul className="CategoryMenu_category_list">
                  {catData &&
                    catData?.map((item, i) => {
                      return (
                        <li className="category-menu" key={item?._id}>
                          <div className="category-menu-img">
                            <span className="span-image">
                              <Link
                                to={`/product/category/${item?.uid}/${item?.slug}`}
                              >
                                {item?.icon ? (
                                  <img src={item?.icon.url} alt="Product" />
                                ) : (
                                  <img
                                    src={categories?.categData[i]?.imgUrl}
                                    alt="Product"
                                  />
                                )}
                              </Link>
                            </span>
                            <Link
                              style={{ padding: "0 10px" }}
                              to={`/product/category/${item?.uid}/${item?.slug}`}
                            >
                              {item?.name}
                            </Link>
                          </div>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
            <div className="searchIcons">
              <div className="callDetail">
                <BiPhoneCall />
                <div className="callText">
                  <h6>call us now</h6>
                  <p>{nums}</p>
                </div>
              </div>
              <div className="searchIconsItem">
                <ul>
                  {/* <li>
                    <Link to="/myAccount">
                      <BsClockHistory />
                    </Link>
                  </li> */}
                  <li>
                    {isLogin !== "true" ? (
                      <Link to="/login">
                        <CiUser />
                      </Link>
                    ) : (
                      <AiOutlineLogout onClick={onLogout} />
                    )}
                  </li>
                  {/* <li className="countWish">
                    <Link to="/wishlist">
                      <AiOutlineHeart />
                      {isLogin === "true" && value?.length > 0 ? (
                        <span className="count">{value?.length}</span>
                      ) : (
                        ""
                      )}
                    </Link>
                  </li> */}
                  {/* <li className="countParent">
                    <Link to="/cart">
                      <BsHandbag />
                      {isLogin === "true" ? (
                        cartLengh > 0 && (
                          <span className="count">{cartLengh}</span>
                        )
                      ) : updatedProducts?.length > 0 ? (
                        <span className="count">{updatedProducts?.length}</span>
                      ) : (
                        ""
                      )}
                    </Link>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SearchBox;

import { useParams } from "react-router-dom"
import BannerSections from "./BannerSections"
import NavigationBars from "./NavigationBars"
import OrecelProducts from "./OrecelProducts"
import axios from "axios"
import { useEffect } from "react"
import { useState } from "react"
function OrecleCategarypage() {
    const [data, setdata] = useState(null)
    const [dataproduct, setdataproduct] = useState(null)

    const params = useParams()
    const token = window.localStorage.getItem('token')
    const getData = async () => {
        try {
            const res = await axios.get(`https://onlineparttimejobs.in/api/category/public`, {
                withCredentials: true,
                headers: {
                    "content-type": "application/json; charset=UTF-8",
                    authorization: `Bearer ${token}`,
                },
            })
            setdata(res.data)
        } catch (error) {

        }
    }

    useEffect(() => {
        getData()
    }, [])


    const [loader, setLoader] = useState(false)
    const getDatas = async () => {
        setLoader(true)
        try {
            const res = await axios.get(`https://onlineparttimejobs.in/api/per/category/product/${params.id}`, {
                withCredentials: true,

                headers: {
                    "content-type": "application/json; charset=UTF-8",
                    authorization: `Bearer ${token}`,
                },
            })
            setdataproduct(res.data)
            setLoader(false)
        } catch (error) {
            setLoader(false)
        }

    }

    const [details , setdetails] = useState()
    const getDatas3 = async () => {
        setLoader(true)
        try {
            const res = await axios.get(`https://onlineparttimejobs.in/api/category/${params.id}`, {
                withCredentials: true,
                headers: {
                    "content-type": "application/json; charset=UTF-8",
                    authorization: `Bearer ${token}`,
                },
            })
            setdetails(res.data)
            setLoader(false)
        } catch (error) {
            setLoader(false)
        }

    }

    useEffect(() => {
        if (params?.id) {
            getDatas()
            getDatas3()
        }
        window.scrollTo(0, 0)
    }, [params?.id])

    return <div>
        {loader && <div className="preloaderCount">
            <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>}
        <BannerSections details={details}/>
        <NavigationBars />
        <OrecelProducts data={data} details={details} dataproduct={dataproduct} />
    </div>
}
export default OrecleCategarypage
function BannerSections({ details }) {
  const obj = details?.banner?.url;
  return (
    <div className="maindix">
      <div className="overCountainer" style={{ backgroundImage: `url(${obj})`, height: "500px" }}></div>
      <div className="innerimg">
        <img src={obj} style={{ width: "100%", height: "100%" }} />
        <h1>{details?.name}</h1>
      </div>
    </div>
  );
}
export default BannerSections;

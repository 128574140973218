import React from "react";

function ContactInfo() {
  return (
    <>
      <section className="contactContainer p-30">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="contactInfo">
                <h4 className="ls-n-25 m-b-1">Contact Info</h4>
                <p>
                  Let’s talk. Don’t hesitate to reach out with the contact information below, or send a message using the form.
                </p>
                <p>Oracle Robotics AI prides itself on delivering scalable and reliable solutions, backed by a team of experts passionate about Robotics and AI. Their dedication to excellence is reflected in the success stories shared by clients who have experienced the transformative impact of their technology.

                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ContactInfo;

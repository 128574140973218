import { BsFillCartFill } from "react-icons/bs";
import { Link } from "react-router-dom";

function CartdOrecalsPro({ item }) {
  return (
    <div className="col-md-4 col-xs-12">
      <div
        className="imagesBox"
        style={{ display: "flex", justifyContent: "center" }}
      >
        {/* <img src="https://s7g10.scene7.com/is/image/hyundaiautoever/Economist_UAM+Image+%283%29_WithoutUber:4x3?wid=640&hei=480&fmt=png-alpha&fit=wrap,1" /> */}
        <img src={item?.product?.variations[0]?.mainImage_url?.url} />
      </div>
      <div
        className="conteBox"
        style={{ textAlign: "center", margin: "10px 0" }}
      >
        <h3>{item?.product.name}.</h3>
        {/* <p>Electric, connected, autonomous: our vision for a smart city of the future involves Plug & Drive solutions, Urban Air Mobility, Purpose Built Vehicles and the Hub.</p> */}
        <div className="buyNowInfo">
          <Link
            to={`/product/${item?.product.uid}/${item?.product.slug}`}
            className="btn btn-danger addCart"
          >
            View Detail
          </Link>
        </div>
      </div>
    </div>
  );
}
export default CartdOrecalsPro;

import axios from "axios";
import React, { useEffect, useState } from "react";

function Careers() {
  const [data, setData] = useState()
  const getData = async () => {
    try {
      const res = await axios.get(`https://onlineparttimejobs.in/api/jobs/public`)
      setData(res.data)
    } catch (error) {

    }
  }
  useEffect(() => {
    getData()
  }, [])
  return (
    <>
      <section className="careersSec p-30">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="careersInfo">
                <div className="careersTitle mb-3">
                  <h1>Work With Us</h1>
                  <strong>
                    Let's do things together! Are you up for it? Let us know!
                    Join etg, by sending us your updated Curriculum Vitae. We
                    ensure our employees have just as many memorable moments as
                    our guests.
                  </strong>
                </div>

                <div className="careersNote mb-2">
                  <div className="joblist">
                    {data && data?.map((item) => {
                      return <div key={item._id} className="jobcard">
                        <h6><span>Name</span> : {item?.name}</h6>
                        <h6><span>Job Code</span> : {item?.jobCode}</h6>
                        <h6><span>Job Department</span> : {item?.jobDepartment?.departmentName}</h6>
                        <h6><span>Job Group</span> : {item?.jobGroup?.name}</h6>
                        <h6><span>Job Description</span> : {item?.jobDescription}</h6>
                        <button type="button" class="btn btn-success">Apply</button>
                      </div>
                    })}


                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Careers;

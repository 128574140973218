import React, { useState } from "react";
import { Link } from "react-router-dom";
import ProductItem from "./product/ProductItem";
import "./Products.css";
import QuiekViewModal from "../../../pages/QueikViewModal/QuiekViewModal";
import { useTranslation } from "react-i18next";

function Products({ productData }) {
  const [show, setShow] = useState(false);

  const [modelDataId, setModelDataId] = useState(null);
  const setProduct_id = (id) => {
    setModelDataId(id);
  };

  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setModelDataId(id);
    setShow(true);
  };

  const { t } = useTranslation();
  return (
    <>
      <section className="productsSection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="fishermanHeader d-flex justify-content-center align-items-end mb-4 mt-4">
                <div className="fisherman-content">
                  <span style={{ marginBottom: "20px", display: "inline-block" }}>{t("WHAT WE CAN DO")}</span>
                  <h5 style={{ color: "#fff" }}>{t("FEATURED CATEGORY")}</h5>
                  {/* <p style={{ color: "#fff" }}>{t('Lorem ipsum dolor sit amet consectetur adipisicing elit')}</p> */}
                </div>
                {/* <div className="fisherman-btn">
                  <Link to="/products" className="optional-btn">
                    {t('View More')}
                  </Link>
                </div> */}
              </div>
            </div>
            <ProductItem productData={productData} handleShow={handleShow} setProduct_id={setProduct_id} />
          </div>
        </div>
        {modelDataId && <QuiekViewModal modelDataId={modelDataId} show={show} onHide={handleClose} size="xl" centered />}
      </section>
    </>
  );
}

export default Products;


import { initializeApp } from 'firebase/app';
import { getAuth, initializeAuth, } from 'firebase/auth'

import firebase from 'firebase';

const firebaseConfig = {
    apiKey: "AIzaSyDIUYgDUmlTCj_hexWl8p1TKI_Zlr1d4UA",
    authDomain: "otp-meatz.firebaseapp.com",
    projectId: "otp-meatz",
    storageBucket: "otp-meatz.appspot.com",
    messagingSenderId: "612125380515",
    appId: "1:612125380515:web:75034e32f06db892d85416"
};

firebase.initializeApp(firebaseConfig);
let auth = firebase.auth();
export { auth, firebase };



// Initialize Firebase
// const app = initializeApp(firebaseConfig);



// export const auth = getAuth(app)

// function FireBase() {
//     return (
//         <>
//             FireBase
//         </>
//     )
// }
// export default FireBase
import axios from "axios"
import { useEffect, useState } from "react"
import { Button, Modal } from "react-bootstrap"
import { base_url } from "../../server"

function DeliveryLocation(props) {
    const [modalShow, setModalShow] = useState(false);

    const [state, setState] = useState({
        insideIndia: true,
        outSideIndia: false
    })

    // const [withIndia, setWithIndia] = useState({
    //     india: ''
    // })

    // console.log(withIndia);

    const [country, setCountry] = useState(null)
    const [selectCountry, setSelectCountry] = useState(null)
    // console.log('kuhu', selectCountry)
    const [put, setPut] = useState(null)
    // console.log('put', put);

    const countrySelect = async () => {
        const res = await axios.get(`${base_url}country`, { withCredentials: true })
        setCountry(res.data)
        filterCounter(res.data)
    }
    const filterCounter = (data) => {
        // const clone = [...country]
        const filter = data.filter((item) => {
            return item._id !== "650ad979a4b4f2e26ffe8d86"
        })
        setCountry(filter)
    }

    const getData = async () => {
        const res = await fetch(`${base_url}settings/country`, {
            method: "GET",
            credentials: "include",
            // 'mode': 'cors',
            headers: {
                accept: "application/json, text/plain, /",
                "content-type": "application/json",
            },
        });
        setSelectCountry(res.data)
    }
    const putData = async (id) => {
        const res3 = await axios.put(`${base_url}settings/change/country`, { id: id }, { withCredentials: true });
        setPut(res3.data)
    }


    const [sendID, setSendId] = useState(null)
    const handleChange = (e) => {
        putData(e.target.value)
        setSendId(e.target.value)
        // setWithIndia(e.target.value)
        // console.log(e.target.value);
    }

    useEffect(() => {
        countrySelect()
        getData()
    }, [])

    const continueIndia = (india) => {

        if (india) {
            setPut(india)
            putData(india)

        } else {
            setPut(sendID)
            putData(sendID)
        }
        props.onHide()


    }



    const showCountry = (str) => {
        const clone = { ...state }
        if (str == 'ins') {
            clone.insideIndia = !state.insideIndia
            clone.outSideIndia = false
        } else {
            clone.outSideIndia = !state.outSideIndia
            clone.insideIndia = false
        }
        setState(clone)
    }
    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName="dilivery-location"

            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter text-center">
                        Select Delivery Location
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Select a delivery location to see product availability</h4>
                    <div className="india-side">
                        <div className="width-india">
                            <div className="form-check" >
                                <input className="form-check-input form-check-input-2" type="radio" onChange={() => showCountry('ins')} checked={state.insideIndia} name="flexRadioDefault" id="flexRadioDefault1" />
                                <label className="form-check-label" htmlFor="flexRadioDefault1">
                                    Within India
                                </label>
                            </div>
                        </div>
                        <div className="outsideIndia">
                            <div className="form-check">
                                <input className="form-check-input form-check-input-2 mr-3" type="radio" onChange={() => showCountry('out')} checked={state.outSideIndia} name="flexRadioDefault" id="flexRadioDefault2" defaultChecked />
                                <label className="form-check-label" htmlFor="flexRadioDefault2">
                                    Outside India
                                </label>
                            </div>
                        </div>
                    </div>
                    {
                        state.insideIndia ? <div className="location-pup">
                            <input type="text" placeholder="* Enter Pincode, Location or Area" />
                            <button className="conti" onClick={() => continueIndia('650ad979a4b4f2e26ffe8d86')}>CONTINUUE SHOPPING</button>
                        </div> : <div className="out-side-india">
                            <select className="form-select" aria-label="Default select example" onChange={handleChange}>
                                <option selected>Select Country</option>
                                {country && country?.map((item) => {
                                    // console.log('item', item);
                                    return <option key={item?._id} value={item._id}>{item?.name}</option>
                                })}
                            </select>

                            <input type="text" placeholder="Enter Delivery City" />
                            <button className="conti"
                                onClick={() => continueIndia()}>CONTINUE SHOPPING</button>
                        </div>
                    }



                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default DeliveryLocation